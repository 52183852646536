import angular from 'angular';

require('jscore/preoday/preoday.min.js');
require('mdPickers/dist/mdPickers.js');
require('angular-sanitize/angular-sanitize.js');
require('angular-material-calendar/dist/angular-material-calendar.js');

import uirouter from '@uirouter/angularjs';
import ocLazyLoad from "oclazyload";
import angularAnimate from 'angular-animate';
import angularAria from 'angular-aria';
import angularMessages from 'angular-messages';
import angularSortableView from 'angular-sortable-view';
import croppie from 'croppie';
import angularMaterial from 'angular-material';
import angularScroll from 'angular-scroll';
import ngInfiniteScroll from 'ng-infinite-scroll';

// Styles
import 'angular-material/angular-material.min.css';
import 'croppie/croppie.css';
import 'mdPickers/dist/mdPickers.css';
import 'angular-material-calendar/dist/angular-material-calendar.css';

window.$script = require('scriptjs');

// Tinycolor is required by `md-color-picker`
// https://www.npmjs.com/package/md-color-picker
window.tinycolor = require('tinycolor2');

//Issue with ES6 Import, change this when it's fixed https://github.com/moment/moment/issues/2608
window.moment = require('moment/moment.js');

require('moment-jdateformatparser');

require('moment/locale/en-gb.js');
require('moment/locale/en-ca.js');
require('moment/locale/nb.js');
require('moment/locale/nl.js');
require('moment/locale/fi.js');
require('moment/locale/da.js');
require('moment/locale/sv.js');
require('moment/locale/fr.js');
require('moment/locale/de.js');
require('moment/locale/es.js');
require('moment/locale/pt.js');
require('moment/locale/pt-br.js');
require('moment/locale/ko.js');
require('moment/locale/da.js');
require('moment/locale/ja.js');
require('moment/locale/pl.js');
require('moment/locale/ru.js');
require('moment/locale/vi.js')

export default angular.module('webapp.vendors', [
  uirouter,
  ocLazyLoad,
  'ngAnimate',
  'ngAria',
  'ngMessages',
  'ngMaterial',
  'angular-sortable-view',
  'mdPickers',
  'materialCalendar',
  'duScroll',
  ngInfiniteScroll,
])
